import axios from "axios";

export const cancelTokenObjectProducer = (calledFrom = "NEW NEW NEW") => {
  const { CancelToken } = axios;
  const source = CancelToken.source();
  console.log(calledFrom, " Token");
  return {
    ...source
  };
};

export const instance = axios.create({
  // baseURL: "https://api.crystalelephant.net",
  baseURL: "https://api.crystalelephant.net/",
  headers: {
    "Content-Type": "application/json"
  },
  timeout: 20000
});

instance.interceptors.response.use(
  req => {
    // console.log("inside interceptor");
    // console.log(req.data.error);
    let newResp = {
      ...req
    };
    if (req.data && req.data.data && req.data.data.error) {
      newResp = {
        ...req,
        error: req.data.data.error.message,
        status: req.data.data.error.code
      };
    }
    return Promise.resolve(newResp);
  },
  error => {
    console.log(error);
    return Promise.reject(error);
  }
);

export function setAuthorizationToken(user) {
  if (user) {
    instance.defaults.headers.common["session-token"] = `${user.session_token}`;
  } else {
    delete instance.defaults.headers.common["session-token"];
  }
}

export default instance;
