import { createStore, applyMiddleware, compose } from "redux";
import autoRehydrate from "redux-persist/lib/autoRehydrate";
import reduxThunk from "redux-thunk";
import { instance as api, setAuthorizationToken } from "../config/axios";
import reducers from "../reducers";
import { LOGOUT_USER } from "../actions/types";

export default (initialSavedState, req) => {
  const composeEnhancers =
    (typeof window !== "undefined" &&
      window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
    compose;
  const initialState =
    initialSavedState ||
    (typeof window !== "undefined" && window.__REDUX_STATE__) ||
    {};

  const store = createStore(
    reducers,
    initialState,
    composeEnhancers(
      applyMiddleware(
        reduxThunk.withExtraArgument({
          api,
          setAuthorizationToken
        })
      ),
      autoRehydrate()
    )
  );
  // Add a response interceptor
  api.interceptors.response.use(
    function (response) {
      // console.log(response);
      return response;
    },
    function (error) {
      // Do something with response erro
      // if (
      //   error &&
      //   error.response &&
      //   error.response.data &&
      //   error.response.data.statusCode === 401
      // ) {
      //   console.log("Here: LOGOUT");
      //   store.dispatch({
      //     type: LOGOUT_USER
      //   });
      // }
      return Promise.reject(error);
    }
  );

  return store;
};
