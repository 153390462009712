import "bootstrap/dist/css/bootstrap-theme.css";
import "bootstrap/dist/css/bootstrap.css";
import "bulma/css/bulma.css";
import localForage from "localforage";
import React, { lazy, Suspense, Component } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { hydrate } from "react-dom";
import { Provider } from "react-redux";
import persistStore from "redux-persist/lib/persistStore";
import { setAuthorizationToken } from "./config/axios";
// import { routes } from "./Routes";
import configureStore from "./store/configureStore";
import browserHistory from "../src/history";
import "../src/styles/global.css";

const Landing = lazy(() => import("./container/Landing"));
const HarvestRound = lazy(() => import("./container/HarvestRound"));
// const CombineRound = lazy(() => import("./container/CombineRound"));
const Sale = lazy(() => import("./container/Sale"));
const Bounce = lazy(() => import("./container/Bounce"));
const Whitepaper = lazy(() => import("./container/Whitepaper"));
const Whitepaper2 = lazy(() => import("./container/Whitepaper2"));
const Lightpaper = lazy(() => import("./container/Litepaper"));
const Audit = lazy(() => import("./container/Audit"));
const Tutorial = lazy(() => import("./container/Tutorial"));
const Referral = lazy(() => import("./container/Referral"));
const store = configureStore(window.__REDUX_STATE__);
const props = {
  store
};
class App extends Component {
  constructor(appProps) {
    super(appProps);
    this.state = { rehydrated: false };
  }
  componentWillMount() {
    persistStore(
      store,
      {
        storage: localForage,
        blacklist: []
      },
      () => {
        // store.dispatch({
        //   type: TURN_ON_DOB_MODAL
        // });
        console.log("app remounted");
        const { user } = store.getState();
        setAuthorizationToken(user);
        this.setState({
          rehydrated: true
        });
      }
    );
  }

  render() {
    if (!this.state.rehydrated) return null;
    // return <Provider store={store}>{routes(props)}</Provider>
    return (
      <Provider store={store}>
        <Router
          history={browserHistory}
          onUpdate={() => window.scrollTo(0, 0)}
          props={props}
        >
          <Suspense fallback={<div></div>}>
            <Switch>
              <Route exact path="/" component={Landing} />
              <Route exact path="/harvestround" component={HarvestRound} />
              <Route exact path="/sale" component={Sale} />
              <Route exact path="/referral" component={Referral} />
              <Route exact path="/bounce" component={Bounce} />
              <Route exact path="/whitepaper" component={Whitepaper} />
              <Route exact path="/whitepaper2" component={Whitepaper2} />
              <Route exact path="/litepaper" component={Lightpaper} />
              <Route exact path="/audit" component={Audit} />
              <Route exact path="/tutorial" component={Tutorial} />
            </Switch>
          </Suspense>
        </Router>
      </Provider>
    );
  }
}

hydrate(<App />, document.getElementById("root"));

// {           //  <h1> {`${props.name}`}</h1>
//  <img className="img" src="/images/pic.jpg" alt="bike" />}
